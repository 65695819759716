/**
 * @author Tran Trung
 * @copyright 06/06/2019 Kyanon Digital
 */

import { takeEvery, call, put } from 'redux-saga/effects';
// import AuthStorage from "utils/AuthStorage";
import fetchApi, { generateRequestId } from 'utils/FetchApi';
import { SINGLE_API } from 'redux/action/type.js';
import { appendRequestId, removeRequestId } from 'redux/action/loadingModal';
import { stores } from '../../index';

function* callApi(action) {
  if (action.type === SINGLE_API) {
    const {
      successType,
      beforeCallType,
      afterCallType,
      afterSuccess,
      errorType,
      afterError,
      ...rest
    } = action.payload;
    if (beforeCallType) {
      yield put({ type: beforeCallType });
    }

    const requestId = generateRequestId();
    stores.dispatch(appendRequestId(requestId));
    const response = yield call(fetchApi, rest);
    if (afterCallType) {
      yield put({ type: afterCallType });
    }
    if (response && !response.error && response.detail !== 'Invalid token.') {
      if (successType) {
        yield put({ type: successType, payload: response });
      }
      if (typeof afterSuccess === 'function') {
        afterSuccess(response);
      }
    } else {
      if (response.detail === 'Invalid token.') {
        yield put({ type: 'LOGOUT_REQUEST' });
      } else {
        if (errorType) {
          yield put({ type: errorType, payload: response.error });
        }

        if (typeof afterError === 'function') {
          afterError(response.error);
        }
      }
    }
    stores.dispatch(removeRequestId(requestId));
  }
}

export default function* () {
  yield takeEvery('*', callApi);
}
