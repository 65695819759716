import Images from 'constants/images.js';
import { stores } from '../../index';

class NoAvailableImage {
  src;
  constructor() {
    this.initialImage();
  }
  async initialImage() {
    try {
      const content = stores.getState()?.contentPage.languageContent.data.data;
      const text = content.imageCtx.msgNoImageAvailable;
      const newSrc = await this.addTextToImage(Images.noImage, text);
      this.src = newSrc;
    } catch (error) {
      console.log('error', error);
    }
  }
  async addTextToImage(imageSrc, text, options = {}) {
    return new Promise((resolve, reject) => {
      // Tạo ảnh từ URL
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = imageSrc;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);

        const defaultOptions = {
          font: '40px Arial',
          textColor: '#939393',
          strokeColor: '#939393',
          textTransform: 'uppercase'
        };

        const { font, textColor, strokeColor, lineWidth, textTransform } = {
          ...defaultOptions,
          ...options
        };

        ctx.font = font;
        ctx.fillStyle = textColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = lineWidth;
        ctx.textTransform = textTransform;

        const textWidth = ctx.measureText(text).width;
        const centerX = (canvas.width - textWidth) / 2;
        const centerY = canvas.height / 1.3;

        ctx.strokeText(text, centerX, centerY);
        ctx.fillText(text, centerX, centerY);
        const imageWithText = canvas.toDataURL();
        resolve(imageWithText);
      };

      img.onerror = (err) => {
        reject(new Error('Failed to load the image.'));
      };
    });
  }

  reInitialInstance() {
    this.initialImage();
  }
}

export const noAvailableImage = new NoAvailableImage();
