export const initialState = {
  translationCatalog: {
    data: [],
    loading: false
  },
  translateFiles: {
    data: [],
    loading: false
  },
  updateContentTranslateStatus: {
    status: false,
    loading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TRANSLATION_CATALOG_FILES_REQUEST':
      return {
        ...state,
        translationCatalog: { ...initialState.translationCatalog, loading: true }
      };
    case 'GET_TRANSLATION_CATALOG_FILES_SUCCESS':
      return {
        ...state,
        translationCatalog: {
          data: action.payload.data,
          loading: false
        }
      };

    case 'GET_TRANSLATION_FILES_BY_LANG_REQUEST':
      return {
        ...state,
        translateFiles: {
          ...initialState.translateFiles,
          loading: true
        }
      };

    case 'GET_TRANSLATION_FILES_BY_LANG_SUCCESS':
      return {
        ...state,
        translateFiles: {
          data: {
            entries: action.payload.data,
            totalCount: action.payload.total_count,
            totalPage: action.payload.total_pages
          },
          loading: false
        }
      };

    case 'UPDATE_CONTENT_TRANSLATE_REQUEST': {
      return {
        ...state,
        updateContentTranslateStatus: {
          ...initialState.updateContentTranslateStatus,
          loading: true
        }
      };
    }

    case 'UPDATE_CONTENT_TRANSLATE_SUCCESS': {
      return {
        ...state,
        updateContentTranslateStatus: {
          loading: false,
          status: true
        }
      };
    }
    default:
      return state;
  }
};
